import React from 'react'
import { GUID } from '../JournalSettings'

const usePWRIConfig = () => {

  const isPWRI = GUID == process.env.REACT_APP_GUID_PWRI;

const pwriClassName = "PWRI"

  return {isPWRI,pwriClassName}

}

export default usePWRIConfig