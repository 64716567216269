import React, { useEffect, useState } from "react";
import { GetJournalIssuesListForMenu } from "../../endpoints";
import { Link } from "react-router-dom";
import { journalLink, journalName } from "../../JournalSettings";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { JournalContext } from "../../store/JournalContext";
import usePWRIConfig from "../../hooks/usePWRIConfig";

function IssuesMenu() {
  const { t } = useTranslation();
  const { hasSpecialIssues, journalName } = useContext(JournalContext);
  const [issues, setIssues] = useState();
  const {isPWRI} = usePWRIConfig()
  useEffect(() => {
    GetJournalIssuesListForMenu().then((res) =>
      setIssues(res.data.journalIssues)
    );
  }, []);

  return (
    <>
      <section className=" mb-2 journalActions">
        <Link
          className="btn btn-outline-success"
          to={`${journalLink}/submit-article/0/article-information`}
          target="_blank"
        >
          {isPWRI ? t("Submit for this journal") :t("submitTo") + " " + journalName}
        </Link>
        <Link
          className="btn btn-outline-success"
          to={`${journalLink}/reviewer-application`}
          target="_blank"
        >
          { isPWRI ? t("Review for this journal") : t("reviewFor") + " " + journalName }
          
        </Link>
      </section>
      {hasSpecialIssues?.isActive && (
        <>
          <h2>{t("Issues")}</h2>
          {issues?.map((el) => {
            const deadline = new Date(el.deadline);
            const deadlineMonth = deadline.getMonth() + 1;
            return (
              <Link
                key={el.id}
                className="issueLink"
                to={`/special-issues/issue${el.id.split("-").join("")}`}
              >
                <h2>{el.name}</h2>
                <p className="text-muted">{el.description}</p>
                {el.deadline && (
                  <span className="deadline text-danger">
                    <b>{t("deadline")}:</b>
                    {deadline.getDate().toString().padStart(2, "0")}/
                    {deadlineMonth.toString().padStart(2, "0")}/
                    {deadline.getFullYear()}
                  </span>
                )}
              </Link>
            );
          })}
          {issues?.length > 0 && (
            <Link to={"/special-issues/0"} className="issueLink">
              {t("showAllIssues")}
            </Link>
          )}
          {issues?.length == 0 && <p>No issue has been posted yet!</p>}
        </>
      )}
    </>
  );
}

export default IssuesMenu;
