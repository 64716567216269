import Homepage from "./pages/Homepage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import RootLayout from "./pages/RootLayout";
import ErrorPathing from "./components/ErrorPathing/ErrorPathing";
import Aims from "./pages/Aims";
import EditorialBoard from "./pages/EditorialBoard";
import ReviewerBoard from "./pages/ReviewerBoard";
import InstructionsForAuthors from "./pages/InstructionsForAuthors";
import Topics from "./pages/Topics";
import Sections from "./pages/Sections";
import EditorialOffice from "./pages/EditorialOffice";
import { journalColor } from "./JournalSettings";
import About from "./pages/About";
import IndexAndArchiving from "./pages/IndexAndArchiving";
import { UserRole, UserRoleType } from "./Enums";
import SpecialIssues from "./pages/SpecialIssues";
import SearchArticles from "./pages/SearchArticles";
import InstructionsForReviewers from "./pages/InstructionsForReviewers";
import InstructionsForEditors from "./pages/InstructionsForEditors";
import ArticleDetails from "./pages/ArticleDetails";
import ArticleFile from "./components/ArticleFile/ArticleFile";
import Preloader from "./UI/Preloader/Preloader";
import { useContext, useEffect } from "react";
import { JournalContext } from "./store/JournalContext";
import SubjectAreas from "./pages/SubjectAreas";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import News from "./pages/News";
import NewsDetails from "./pages/NewsDetails";
import Conference3 from "./components/Conferences/Conference3/Conference3";
import Conference4 from "./components/Conferences/Conference4/Conference4";
import Conference1 from "./components/Conferences/Conference1/Conference1";
import AboutConference1 from "./components/Conferences/Conference1/AboutConference1";
import KeynoteSpeakersConference1 from "./components/Conferences/Conference1/KeynoteSpeakersConference1";
import OrganizingCommitteeConference1 from "./components/Conferences/Conference1/OrganizingCommitteeConference1";
import ScientificCommitteeConference1 from "./components/Conferences/Conference1/ScientificCommitteeConference1";
import ThematicTopicsConference1 from "./components/Conferences/Conference1/ThematicTopicsConference1";
import RegistrationForConference1 from "./components/Conferences/Conference1/RegistrationForConference1";
import ProgramConference1 from "./components/Conferences/Conference1/ProgramConference1";
import AbstractSubmissionConference1 from "./components/Conferences/Conference1/AbstractSubmissionConference1";
import BookOfAbstractsConference1 from "./components/Conferences/Conference1/BookOfAbstractsConference1";
import PosterPresentationConference1 from "./components/Conferences/Conference1/PosterPresentationConference1";
import SupportingJournalsConference4 from "./components/Conferences/Conference4/SupportingJournalsConference4";
import SponsorshipConference1 from "./components/Conferences/Conference1/SponsorshipConference1";
import SupportingScientificJournalsConference1 from "./components/Conferences/Conference1/SupportingScientificJournalsConference1";
import VenueConference1 from "./components/Conferences/Conference1/VenueConference1";
import SecretariatContactConference1 from "./components/Conferences/Conference1/SecretariatContactConference1";
import OrganizingPartnersConference1 from "./components/Conferences/Conference1/OrganizingPartnersConference1";
import AboutConference4 from "./components/Conferences/Conference4/AboutConference4";
import TopicsConference4 from "./components/Conferences/Conference4/TopicsConference4";
import ProgramConference4 from "./components/Conferences/Conference4/ProgramConference4";
import AbstractSubmissionConference4 from "./components/Conferences/Conference4/AbstractSubmissionConference4";
import AbstractBookConference4 from "./components/Conferences/Conference4/AbstractBookConference4";
import PaperSubmissionConference4 from "./components/Conferences/Conference4/PaperSubmissionConference4";
import ComitteesConference4 from "./components/Conferences/Conference4/ComitteesConference4";
import SponsorshipConference4 from "./components/Conferences/Conference4/SponsorshipConference4";
import VenueConference4 from "./components/Conferences/Conference4/VenueConference4";
import OrganizingPartnersConference4 from "./components/Conferences/Conference4/OrganizingPartnersConference4";
import ContactConference4 from "./components/Conferences/Conference4/ContactConference4";
import usePWRIConfig from "./hooks/usePWRIConfig";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <Homepage /> },
      { path: "/special-issues/:sectionId", element: <SpecialIssues /> },
      { path: "/aims", element: <Aims /> },
      { path: "subject-areas", element: <SubjectAreas /> },
      {
        path: "/editorial-board",
        element: <EditorialBoard />,
      },
      {
        path: "/reviewers",
        element: (
          <ReviewerBoard
            userRole={[UserRole.Reviewer]}
            isReviewerBoard={false}
            roleType={UserRoleType.Reviewer}
          />
        ),
      },
      {
        path: "/reviewer-board",
        element: (
          <ReviewerBoard
            userRole={[UserRole.ReviewerBoard]}
            isReviewerBoard={true}
            roleType={UserRoleType.ReviewersBoard}
          />
        ),
      },
      {
        path: "/instructions-for-authors",
        element: <InstructionsForAuthors />,
      },
      {
        path: "/instructions-for-reviewers",
        element: <InstructionsForReviewers />,
      },
      {
        path: "/instructions-for-editors",
        element: <InstructionsForEditors />,
      },
      {
        path: "/topics",
        element: <Topics />,
      },
      {
        path: "/sections",
        element: <Sections />,
      },
      {
        path: "/editorial-office",
        element: <EditorialOffice />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/index-archiving",
        element: <IndexAndArchiving />,
      },
      {
        path: "/search-articles",
        element: <SearchArticles />,
      },
      {
        path: "/article/:id",
        element: <ArticleDetails />,
      },
      { path: "/news", element: <News /> },
      { path: "/news-details/:newsId", element: <NewsDetails /> },
    ],
    errorElement: <ErrorPathing />,
  },
  {
    path: "/view-article/:id",
    element: <ArticleFile />,
  },
  {
    path: "/conference-1",
    element: <Conference1 />,
    children: [
      {
        path: "about",
        element: <AboutConference1 />,
      },
      {
        path: "keynote-speakers",
        element: <KeynoteSpeakersConference1 />,
      },
      {
        path: "organizing-committee",
        element: <OrganizingCommitteeConference1 />,
      },
      {
        path: "scientific-committee",
        element: <ScientificCommitteeConference1 />,
      },
      {
        path: "conference-thematic-topics",
        element: <ThematicTopicsConference1 />,
      },
      {
        path: "registration",
        element: <RegistrationForConference1 />,
      },
      {
        path: "conference-program",
        element: <ProgramConference1 />,
      },
      {
        path: "abstract-submission",
        element: <AbstractSubmissionConference1 />,
      },
      {
        path: "book-of-abstracts",
        element: <BookOfAbstractsConference1 />,
      },
      {
        path: "poster-presentation",
        element: <PosterPresentationConference1 />,
      },
      {
        path: "supporting-journals",
        element: <SupportingScientificJournalsConference1 />,
      },
      {
        path: "sponsorship",
        element: <SponsorshipConference1 />,
      },
      {
        path: "venue",
        element: <VenueConference1 />,
      },
      {
        path: "secretariat-contact",
        element: <SecretariatContactConference1 />,
      },
      {
        path: "organizing-partners",
        element: <OrganizingPartnersConference1 />,
      },
    ],
  },

  // {
  //   path: "/conference/conference-2",
  //   element: <Conference2 />,
  // },
  // {
  //   path: "/conference/conference-3",
  //   element: <Conference3 />,
  // },
  {
    path: "/conference-4",
    element: <Conference4 />,
    children: [
      {
        path: "about",
        element: <AboutConference4 />,
      },
      {
        path: "topics",
        element: <TopicsConference4 />,
      },
      {
        path: "conference-program",
        element: <ProgramConference4 />,
      },
      {
        path: "abstract-submission",
        element: <AbstractSubmissionConference4 />,
      },
      {
        path: "abstract-book",
        element: <AbstractBookConference4 />,
      },
      {
        path: "paper-submission",
        element: <PaperSubmissionConference4 />,
      },
      {
        path: "supporting-journals",
        element: <SupportingJournalsConference4 />,
      },
      {
        path: "conference-committees",
        element: <ComitteesConference4 />,
      },
      {
        path: "sponsorship",
        element: <SponsorshipConference4 />,
      },
      {
        path: "venue",
        element: <VenueConference4 />,
      },
      {
        path: "organizing-partners",
        element: <OrganizingPartnersConference4 />,
      },
      {
        path: "contact",
        element: <ContactConference4 />,
      },
    ],
  },
]);

const GlobalStyles = createGlobalStyle`
:root {
  --main800:rgba(${journalColor}, 1);
  --main01:rgba(${journalColor}, 0.1);
  --main05:rgba(${journalColor}, 0.5);
  --mainLighterAccent: #0070a8;
  --backgroundColorLogin: #f7f3ec;
  --inputBorderColor: #;
  --inputBorderColorFocus: rgba(87, 98, 127, 0.7);
  --black: #000000;
  --grayish: #dadada;
  --mainNavHover: white;
  --paddingLeftRight: 5%;
  --footerBackground: #333;
  --textColor: #1a1a1a;
  --textMuted: #a1a1a1;
}`;

function App() {
  const { appIsReady, journalLanguages } = useContext(JournalContext);
  const {isPWRI,pwriClassName} = usePWRIConfig()
  // const languageValue = journalLanguages
  //   ? JSON.parse(journalLanguages.value)[0]
  //   : null;
  const { i18n } = useTranslation();

  let languageValue = "en-us";
  if (journalLanguages) {
    const parsedLanguages = JSON.parse(journalLanguages);
    if (parsedLanguages.includes(localStorage.getItem("lng"))) {
      languageValue = localStorage.getItem("lng");
    } else {
      languageValue = parsedLanguages[0];
    }
  }

  if (i18next.language !== languageValue) {
    i18n.changeLanguage(languageValue);
  }


  return (
    <div className={`${isPWRI ? pwriClassName : ""}`}>
      <GlobalStyles />
      <Preloader isReady={appIsReady} minHeight="100vh">
        <RouterProvider router={routes} />
      </Preloader>
    </div>
  );
}

export default App;
