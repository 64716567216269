import INT_EAS_LOGO from "./Assets/Images/INT-EAS.png";
import JSCT_LOGO from "./Assets/Images/JCST.png";
import COMPUTERS_BACKGROUND from "./Assets/Images/computersbackground.jpg";
import PSYCHOLOGY_BACKGROUND from "./Assets/Images/psychology-background.jpg";
import SEMINARI_LOGO from "./Assets/Images/SEMINARI.png";
import SEMINARI_FAV_ICON from "./Assets/Images/SEMINARI_FAV_ICON.png";
import JCST_FAV_ICON from "./Assets/Images/JCST_FAV_ICON.svg";
import INT_EAS_FAV_ICON from "./Assets/Images/INT-EAS_FAV_ICON.svg";
import SEMINARI_BACKGROUND from "./Assets/Images/SEMINARI_BACKGROUND.jpg";

let baseURL = "";
let GUID = "";
let journalName = "";
let journalColor = "";
let journalLogo = null;
let journalLongName = "";
let journalImage = "";
let journalFavIcon = null;
let journalLink = null;

baseURL = process.env.REACT_APP_API_URL;

const currentUrl = window.location.href.toString().toLocaleLowerCase();
if (currentUrl.includes("jcst") || currentUrl.includes("3000")) {
  GUID = process.env.REACT_APP_GUID_JSCT;
  journalName = "JCST";
  journalColor = "1, 50, 75";
  journalLogo = JSCT_LOGO;
  journalFavIcon = JCST_FAV_ICON;
  journalLongName = "Journal of Contemporary Sciences and Technologies";
  journalImage = COMPUTERS_BACKGROUND;
  journalLink = `https://system.jcst-journal.com`;
}

if (currentUrl.includes("pwri") || currentUrl.includes("3001")) {
  GUID = process.env.REACT_APP_GUID_PWRI;
  journalName = "Psychological Wellbeing: Research and Interventions";
  journalColor = "0, 111, 81";
  journalLongName = "Psychological Wellbeing: Research and Interventions";
  journalImage = PSYCHOLOGY_BACKGROUND;
  journalLink = "https://system.pwri-journal.com";
}

if (currentUrl.includes("seminari") || currentUrl.includes("3002")) {
  GUID = process.env.REACT_APP_GUID_SEMINARI;
  journalName = "SEMINARI";
  journalColor = "0, 89, 125";
  journalLogo = SEMINARI_LOGO;
  journalFavIcon = SEMINARI_FAV_ICON;
  journalLongName = "Journal in Albanological Studies";
  journalImage = SEMINARI_BACKGROUND;
  journalLink = "https://system.seminari-pr.com";
}

if (currentUrl.includes("int-eas") || currentUrl.includes("3003")) {
  GUID = process.env.REACT_APP_GUID_INT_EAS;
  journalName = "INT-EAS";
  journalColor = "21, 64, 67";
  journalLogo = INT_EAS_LOGO;
  journalFavIcon = INT_EAS_FAV_ICON;
  journalLongName =
    "Journal of interdisciplinarity studies: environment and sustainability";
  journalImage =
    "https://pub.mdpi-res.com/title_story/title_story_16835938537077.jpg?1683885622";
  journalLink = "https://system.int-eas.com";
}

if (
  currentUrl.includes("icna-sta") ||
  currentUrl.includes("3004") ||
  currentUrl.includes("konferenca")
) {
  GUID = process.env.REACT_APP_GUID_ICNA_STA;
  journalName = "ICNA-STA";
  journalColor = "0, 49, 98";
  journalLongName =
    "International Conference on New Achievements in Science, Technology and Arts";
  journalImage =
    "https://pub.mdpi-res.com/title_story/title_story_16808354843392.jpg?1683885622";
  journalLink = "https://system.konferenca.net";
}

if (currentUrl.includes("ijml") || currentUrl.includes("3005")) {
  GUID = process.env.REACT_APP_GUID_IJML;
  journalName = "IJML";
  journalColor = "140,75,153";
  journalLongName = "International Journal of Management and Leadership";
  journalImage =
    "https://pub.mdpi-res.com/title_story/title_story_16831963667422.jpg?1683885622";
  journalLink = "https://system.ijml-journal.com";
}

if (currentUrl.includes("jhcw") || currentUrl.includes("3006")) {
  GUID = process.env.REACT_APP_GUID_JHCW;
  journalName = "JHCW";
  journalColor = "147,86,84";
  journalLongName = "Journal of Human Care and Wellbeing";
  journalImage =
    "https://pub.mdpi-res.com/title_story/title_story_1689216655977.jpg?1692956976";
  journalLink = "https://system.jhcw-journal.com";
}

const changeFavIcon = () => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    if (!journalLogo) return;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  if (!journalLogo) return;
  link.href = journalFavIcon || journalLogo;
};

document.title = `${journalLongName} - Portal`;
changeFavIcon();
export { baseURL, GUID, journalColor, journalLogo, journalImage, journalLink };
